/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEndpointData } from "RootDir/declarations/endpoint";
import { BASE_API_SERVER } from "../constants/common";

const authUrl: string = `${BASE_API_SERVER}/auth`;

export const AUTHORIZATION = {
  LOGIN: {
    method: 'POST',
    url: `${authUrl}/login`,
  } as IEndpointData,
  REFRESH_TOKEN: {
    method: 'POST',
    url: `${authUrl}/refresh`,
  } as IEndpointData,
  LOGOUT: {
    method: 'GET',
    url: `${authUrl}/logout`,
  } as IEndpointData,
};
