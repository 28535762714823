
import { defineComponent, ref } from 'vue';
import { useVisible } from 'RootDir/common/composables/useVisible';
import { RUSSIAN_LOCALE } from 'RootDir/common/constants/datepicker';

export default defineComponent({
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    hideBottomSpace: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String || Date,
      required: false,
    },
    maxDate: {
      type: String || Date,
      required: false,
    },
  },
  setup(props, {emit}) {
    const date = ref('');
    const { visible, changeVisibility } = useVisible();

    const toRussianDate = (dateForChange: string): [Date, string] => {
      const newDate = new Date(dateForChange);
      const russianDate = newDate.toLocaleString('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      return [newDate, russianDate];
    }
    const changeDate = () => {
      changeVisibility();
      let changedDate;
      [changedDate, date.value] = toRussianDate(date.value);
      emit('input', date.value);
    }

    const classesArray = ref([
      's-input',
      props.disable ? 's-input--disable' : '',
      props.required ? 'required' : '',
    ] as Array<string>);

    const classes = ref(classesArray.value.join(' ') as string);

    return {
      date,
      changeDate,
      visible,
      changeVisibility,
      RUSSIAN_LOCALE,
      classes,
    }
  },
})
