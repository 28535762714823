import {StoreFlag} from 'RootDir/declarations/store/store-flag';

function state(): StoreFlag.AuthStateInterface {
  return {
    accessToken: null,
    refreshToken: null,
  };
}

export default state;
