import { MutationTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const mutation: MutationTree<StoreFlag.AppStateInterface> = {
  set(state: any, { type, value }) {
    state[type] = value;
  },
};

export default mutation;
