import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    loading: _ctx.loading,
    disable: _ctx.disable,
    class: _normalizeClass(_ctx.classes),
    label: _ctx.label,
    type: _ctx.type,
    "no-caps": !_ctx.caps
  }, {
    loading: _withCtx(() => [
      _createVNode(_component_q_spinner_dots)
    ]),
    default: _withCtx(() => [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["loading", "disable", "class", "label", "type", "no-caps"]))
}