import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    class: _normalizeClass(_ctx.classes),
    outlined: "",
    dense: "",
    disable: _ctx.disable,
    "hide-bottom-space": _ctx.hideBottomSpace
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2
  }, [
    _renderList(_ctx.slots, (_, slotName) => {
      return {
        name: slotName,
        fn: _withCtx(() => [
          _renderSlot(_ctx.$slots, slotName)
        ])
      }
    })
  ]), 1032, ["class", "disable", "hide-bottom-space"]))
}