
import { defineComponent, PropType, ref } from 'vue';
import { Buttons } from 'RootDir/declarations/components/s-btn';
import { ComponentVariantsValidator } from 'RootDir/validators/components';

const btnVariants = [
  'main',
  'second',
  'accent',
];

export default defineComponent({
  name: 'SBtn',
  props: {
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<Buttons.BtnVariants>,
      default: 'main',
      validator: (el:string) => ComponentVariantsValidator(el, btnVariants, 'Button'),
    },
    outline: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    caps: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const classesArray = ref([
      's-btn',
      props.outline ? 's-btn--outline' : '',
      props.flat ? 's-btn--flat' : '',
      props.round ? 's-btn--round' : '',
      props.variant,
    ] as Array<string>);

    const classes = ref(classesArray.value.join(' ') as string);
    return {
      classes,
    }
  },
})
