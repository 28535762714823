
import { defineComponent, PropType, Ref, ref } from 'vue';
import { Dialogs } from 'RootDir/declarations/components/s-dialog';
import { ComponentVariantsValidator } from 'RootDir/validators/components';

const dialogSizeVariants = [
  'extra-small',
  'small',
  'middle',
  'large',
  'big',
];

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    rightIconClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<Dialogs.DialogSizeVariants>,
      default: 'middle',
      validator: (el:string) => ComponentVariantsValidator(el, dialogSizeVariants, 'Dialog'),
    }
  },
  setup(props) {
    const visible: Ref<boolean> = ref(props.show);
    return {
      visible,
    }
  },
})
