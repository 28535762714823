import { createApp } from 'vue';
import App from './App.vue';
import router from 'RootDir/router';
import store from 'RootDir/store';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import QuasarConfig from 'RootDir/config/quasar-config';
import Plugins from 'RootDir/config/plugins';
import GlobalComponents from 'RootDir/config/components';
import RouteMiddleware from 'RootDir/config/route-middleware';
import 'RootDir/assets/styles/app.scss';
import '@quasar/extras/material-icons/material-icons.css';

RouteMiddleware(router, store);
Plugins(store);

// Вынести это нахуй отсюда
const QuasarOptions = {
  QuasarConfig,
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
  config: {
    notify: {},
    loading: {},
    dialog: {},
  },
};

const Vue = createApp(App)
  .use(Quasar, QuasarOptions)
  .use(store)
  .use(router);

GlobalComponents(Vue);
Vue.mount('#app');
