
import { useCurrentUser } from 'RootDir/common/composables/useCurrentUser';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  setup() {
    const {
      accessToken,
      getUserInfo
    } = useCurrentUser();

    const init = () => {
      if (accessToken.value) getUserInfo();
    }

    init();

    return {}
  }
});
