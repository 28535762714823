/* eslint-disable @typescript-eslint/no-inferrable-types */
import { isEmptyOrNullString } from "../validators/strings";
import { Ref } from 'vue';

/**
 * Генерация полного ФИО
 * @param lastName
 * @param firstName
 * @param patronymic
 */
 export const generateFIO = (
  lastName: string = '',
  firstName: string = '',
  middleName: string = ''
): string => {
  const result: Array<string> = [];
  if (!isEmptyOrNullString(lastName)) {
    result.push(lastName);
  }
  if (!isEmptyOrNullString(firstName)) {
    result.push(firstName);
    if (!isEmptyOrNullString(middleName)) {
      result.push(middleName);
    }
  }
  return result.join(' ');
};

/**
 * Проверяет есть ли аватарка, если нет, то ставится дефолтная
 * @param data
 */
export const setDefaultAvatar = (data: any) => {
  if (typeof data.avatar !== 'undefined')
    data.avatar = '/icons/user_icon.svg';
}
