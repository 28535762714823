
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SPaginate',
  props: {
    max: {
      type: [String, Number],
      default: '5',
    },
    directionLinks: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {

    }
  },
})
