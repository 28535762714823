import { RouteRecordRaw } from 'vue-router';

const GenresPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Handbooks/Genres/pages/List.vue'
  );

const AuthorsPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Handbooks/Authors/pages/List.vue'
  );

export default [
  {
    path: "genres",
    component: GenresPage,
    name: "genres",
    meta: {
      title: 'Ugramat.Books Жанры'
    }
  },
  {
    path: "authors",
    component: AuthorsPage,
    name: "authors",
    meta: {
      title: 'Ugramat.Books Авторы'
    }
  },
] as Array<RouteRecordRaw>;
