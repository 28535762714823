import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!

  return (_openBlock(), _createBlock(_component_q_btn_toggle, {
    class: _normalizeClass(["s-toggle", _ctx.styles?.variant]),
    unelevated: "",
    "toggle-color": _ctx.styles?.toggleColor,
    color: _ctx.styles?.color,
    "no-caps": _ctx.noCaps,
    "text-color": _ctx.styles?.textColor,
    options: _ctx.options
  }, null, 8, ["class", "toggle-color", "color", "no-caps", "text-color", "options"]))
}