import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Auth from './auth';
import Administration from './administration';
import HandBook from './handbook';
import Order from './order';
import Box from './box';
import Books from './books';

const MainLayout = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'
  );

const AuthLayout = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "AuthLayout" */ '../layouts/AuthLayout.vue'
  );

const RouterViewComponent = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "RouterViewComponent" */ 'RootDir/components/RouterViewComponent.vue'
  );

const UIKit = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "UIKit" */ 'RootDir/modules/__BrandBook__/UIKit.vue'
  );

// Стартовая позиция для всех страниц
export const routerInitialPath = '/';

const routes: Array<RouteRecordRaw> = [
  {
    path: routerInitialPath,
    component: MainLayout,
    children: [
      {
        path: 'administration',
        component: RouterViewComponent,
        children: Administration,
      },
      {
        path: 'account',
        component: () => import('RootDir/modules/Account/pages/Account.vue'),
        name: 'account',
        meta: {
          title: 'Ugramat.Books Мой аккаунт'
        }
      },
      {
        path: 'handbooks',
        component: RouterViewComponent,
        children: HandBook,
      },
      {
        path: 'books',
        component: RouterViewComponent,
        children: Books,
      },
      {
        path: 'orders',
        component: RouterViewComponent,
        children: Order,
      },
      {
        path: 'boxes',
        component: RouterViewComponent,
        children: Box,
      },
    ],
  },
  {
    path: `${routerInitialPath}auth`,
    component: AuthLayout,
    children: Auth,
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('RootDir/modules/Error/Error404.vue'),
    name: 'error',
    meta: {
      title: 'Ugramat.Books Не найдено'
    }
  },
]

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (routes[0].children as Array<any>).push({
    path: 'ui-kit',
    name: 'ui-kit',
    component: UIKit,
  })
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

