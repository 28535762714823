import { useNotifications } from "RootDir/common/composables/useNotifications";
import axios, { AxiosError } from "axios";
import { HTTP_CODE_ERROR } from "RootDir/common/constants/http-code";
import router, { routerInitialPath } from "RootDir/router";
import { AUTHORIZATION } from "RootDir/common/endpoints/auth";
import { useCurrentUser } from "RootDir/common/composables/useCurrentUser";
import store from "RootDir/store";

let unauthorizedCounter = 0;
const authLoginRoute = `${routerInitialPath}auth/login`;
const { $userDataSet } = useCurrentUser();
const $notification = useNotifications();
const $route = router;
const refreshToken = store.getters['auth/refreshToken'];

// TODO: Не вызывается уведомление от сюда!!!!
const userLogout = () => {
  // const message = 'Ошибка авторизации, пожалуйста, пройдите процедуру авторизации ещё раз!';
  store.dispatch('auth/setAccessToken', '');
  store.dispatch('auth/setRefreshToken', '');
  store.dispatch('user/setUser', null);
  $route.push({name: 'login'});
  // return message;
};


const httpErrorHandle = async (error: AxiosError): Promise<unknown> => {
  // const $notification = useNotifications();
  // Если нет соединения с сервером
  if (typeof error.response === 'undefined') {
    const message = 'Ошибка соединения с сервером, через несколько минут всё заработает!';
    return Promise.reject(error);
  }

  // return;

  if (error.response.status === HTTP_CODE_ERROR.UNAUTHORIZED) {
    unauthorizedCounter += 1;

    // Если код 401 возвращает с метода обновления токена
    // то всё, пора выходить
    if (error.config?.url === AUTHORIZATION.REFRESH_TOKEN.url) {
      userLogout();
      return Promise.reject(error);
    }

    // Если произошло по какой-то причине более 25 запросов одновременно и они получили
    // 401 значит что-то идёт не так и надо выкинуть пользователя.
    if (unauthorizedCounter > 25) {
      unauthorizedCounter = 0;
      userLogout();
      return Promise.reject(error);
    }

    if ($route.currentRoute.value.path !== authLoginRoute) {
      const data = {refresh_token: refreshToken}

      const newPromiseToken = await axios({
        ...AUTHORIZATION.REFRESH_TOKEN,
        data
      })
        .then((r: any) => {
          store.dispatch('auth/setAccessToken', r.data.data.access_token);
          store.dispatch('auth/setRefreshToken', r.data.data.refresh_token);
          // store.dispatch('user/setUser', r.data.data.refresh_token);
          // $userDataSet.setUser(r.data.user);

          unauthorizedCounter = 0;

          return Promise.resolve(axios(error?.response?.config as any));
        })
        .catch((e) => {
          userLogout();
          return Promise.reject(error);
        });

      return newPromiseToken;
    }

  }

  // 429 Too Many Requests («слишком много запросов»)
  if (error.response.status === HTTP_CODE_ERROR.MANY_REQUESTS) {
    // const message =
    //   'Превышено допустимое количество запросов. Пожалуйста, повторите через несколько минут!';
    // $notification.error(message);
  }
}

export default httpErrorHandle;
