import SBtn from 'RootDir/components/Buttons/SBtn.vue';
import SBtnIcon from 'RootDir/components/Buttons/SBtnIcon.vue';
import SInput from 'RootDir/components/Inputs/SInput.vue';
import SBtnToggle from 'RootDir/components/Buttons/SBtnToggle.vue'
import SSelect from 'RootDir/components/Selects/SSelect.vue';
import SPaginate from 'RootDir/components/Paginate/SPaginate.vue';
import SIcon from 'RootDir/components/Icons/SIcon.vue';
import SHeaderDefault from 'RootDir/components/Header/SHeaderDefault.vue';
import SPageContent from 'RootDir/components/Page/SPageContent.vue';
import SImg from 'RootDir/components/Images/SImg.vue';
import SDialog from 'RootDir/components/Dialogs/SDialog.vue';
import ErrorMessage from 'RootDir/components/Banners/ErrorMessage.vue';
import SDateInput from 'RootDir/components/DateInput/SDateInput.vue';
import SFilters from 'RootDir/components/SFilters.vue';

export default (App: any) => {
  App.component('SBtn', SBtn);
  App.component('SBtnIcon', SBtnIcon);
  App.component('SInput', SInput);
  App.component('SBtnToggle', SBtnToggle);
  App.component('SSelect', SSelect);
  App.component('SPaginate', SPaginate);
  App.component('SIcon', SIcon);
  App.component('SHeaderDefault', SHeaderDefault);
  App.component('SPageContent', SPageContent);
  App.component('SImg', SImg);
  App.component('SDialog', SDialog);
  App.component('ErrorMessage', ErrorMessage);
  App.component('SDateInput', SDateInput);
  App.component('SFilters', SFilters);
}
