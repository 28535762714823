import { StoreFlag } from 'RootDir/declarations/store/store-flag';
import { MutationTree } from 'vuex';

const mutation: MutationTree<StoreFlag.PaginationsStateInterface> = {
  set(state: any, { type, value }) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    state[type] = value;
  },
  destroyAll(state: StoreFlag.PaginationsStateInterface) {
    state.usersPagination = {};
    state.logsPagination = {};
    state.authorsPagination = {};
    state.genresPagination = {};
    state.contactsPagination = {};
    state.ugramatBooksPagination = {};
    state.ordersPagination = {};
  },
};

export default mutation;
