import { StoreFlag } from "RootDir/declarations/store/store-flag";
import { Router } from "vue-router";
import { Store } from "vuex";

const whiteListRouteNames: Array<string> = [
  'login',
];

export default async (router: Router, store: Store<any>) => {
  router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    document.title = to.meta.title as string;
    if (whiteListRouteNames.indexOf(to.name as string) !== -1 || isAuthenticated) {
      next();
    } else {
      router.push({
        name: 'login',
        query: {
          redirectTo: to.fullPath,
        },
      });
    }
  });
}
