
import { isEmptyOrNullString } from '@/validators/strings';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';


export default defineComponent({
  props: {
    viewBtn: {
      type: Boolean,
      default: false,
    },
    routeBackName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const $router = useRouter();
    const back = () => {
      if (isEmptyOrNullString(props.routeBackName)) $router.back();
      else $router.push({name: props.routeBackName});
    }
    return {
      back,
    }
  },
})
