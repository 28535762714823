import { Store } from "vuex";
import axios, { AxiosStatic, AxiosError, ParamsSerializerOptions } from "axios";
import { axiosParamsSerializer } from "./axios";
import httpErrorHandle from "./http-error-handler";


export default async (store: Store<any>) => {
  axios.interceptors.request.use((config: any) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    if (isAuthenticated) {
      const token = store.getters['auth/accessToken'];
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  if (axios?.defaults?.headers?.common?.['Access-Control-Allow-Origin']) {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  }

  // axios.defaults.params = axiosParamsSerializer();


  // Обработка ошибок
  axios.interceptors.response.use(
    (r) => r,
    async (error: AxiosError) => {
      httpErrorHandle(error);
      return Promise.reject(error);
    }
  )
};
