import { Module } from 'vuex';
import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const fillterModule: Module<StoreFlag.FiltersStateInterface, StoreFlag.StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};

export default fillterModule;
