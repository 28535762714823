import { RouteRecordRaw } from 'vue-router';

const RouterViewComponent = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "RouterViewComponent" */ 'RootDir/components/RouterViewComponent.vue'
  );

const RolesPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Roles/pages/List.vue'
  );

const StatusesPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Statuses/pages/List.vue'
  );

const ActionLogsPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Logs/pages/List.vue'
  );

const UsersPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Users/pages/List.vue'
  );

const UserPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Users/pages/Index.vue'
  );

const LogPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Logs/pages/Index.vue'
  );

const ContactsPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Administration/Contacts/pages/List.vue'
  );

export default [
  {
    path: "roles",
    component: RolesPage,
    name: "roles",
    meta: {
      title: 'Ugramat.Books Роли'
    }
  },
  {
    path: "contacts",
    component: ContactsPage,
    name: "contacts",
    meta: {
      title: 'Ugramat.Books Отклики'
    }
  },
  {
    path: "statuses",
    component: StatusesPage,
    name: "statuses",
    meta: {
      title: 'Ugramat.Books Статусы'
    }
  },
  {
    path: 'logs',
    component: RouterViewComponent,
    children: [
      {
        path: "",
        component: ActionLogsPage,
        name: "logs",
        meta: {
          title: 'Ugramat.Books Логи'
        }
      },
      {
        path: ":id",
        component: LogPage,
        name: "log",
        meta: {
          title: 'Ugramat.Books Лог'
        }
      },
    ]
  },
  {
    path: "users",
    component: RouterViewComponent,
    children: [
      {
        path: "",
        component: UsersPage,
        name: "users",
        meta: {
          title: 'Ugramat.Books Пользователи'
        }
      },
      {
        path: ":id",
        component: UserPage,
        name: "user",
        children: [
          {
            path: 'information',
            name: 'user-information',
            component: () => import('../modules/Administration/Users/components/Information.vue'),
            meta: {
              title: 'Ugramat.Books Пользователь'
            }
          },
          {
            path: 'logs',
            name: 'user-logs',
            component: () => import('../modules/Administration/Users/components/Logs.vue'),
            meta: {
              title: 'Ugramat.Books Логи пользователя'
            }
          },
          {
            path: 'orders',
            name: 'user-orders',
            component: () => import('../modules/Administration/Users/components/Orders.vue'),
            meta: {
              title: 'Ugramat.Books Заказы пользователя'
            }
          },
        ],
      },
    ]
  },
] as Array<RouteRecordRaw>;
