const LoginPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Authorization/pages/LoginPage.vue'
  );

export default [
  {
    path: "",
    component: LoginPage,
    name: "login",
    meta: {
      title: 'Ugramat.Books Вход'
    }
  },
];
