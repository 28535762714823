import axios from "axios";
import { ACCOUNT } from "RootDir/common/endpoints/user";

export default class AccountApi {
  /**
   * Получение информации о себе
   * @returns
   */
  public static async index(): Promise<{ data: Administration.SystemUser }> {
    const responseData: { data: Administration.SystemUser } = await axios({
      ...ACCOUNT.INDEX,
    })
      .then((r): { data: Administration.SystemUser } => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }

  /**
   * Обновление своей информации
   * @param data
   * @returns
   */
  public static async update(data: Administration.SystemUser): Promise<{ message: string }> {
    const responseData: { message: string } = await axios({
      ...ACCOUNT.UPDATE,
      data
    })
      .then((r): { message: string} => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }

  /**
   * Обновление своего города
   * @param data
   * @returns
   */
  public static async updateAddress(data: {value: string}): Promise<{ message: string }> {
    const responseData: { message: string } = await axios({
      ...ACCOUNT.UPDATE_ADDRESS,
      data
    })
      .then((r): { message: string} => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }
}
