import { Ref, ref } from "vue"

export function useVisible() {
    const visible: Ref<boolean> = ref(false);
    const changeVisibility = async () => {
        visible.value = !visible.value;
    }

    return {
        visible,
        changeVisibility
    }
}
