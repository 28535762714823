import { RouteRecordRaw } from 'vue-router';

const OrdersPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Orders/pages/List.vue'
  );

const OrdersTelegramPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/OrdersTelegram/pages/List.vue'
  );

export default [
  {
    path: "",
    component: OrdersPage,
    name: "orders",
    meta: {
      title: 'Ugramat.Books Заказы'
    }
  },
  {
    path: "telegram",
    component: OrdersTelegramPage,
    name: "orders-telegram",
    meta: {
      title: 'Ugramat.Books Заказы через Telegram'
    }
  }
] as Array<RouteRecordRaw>;
