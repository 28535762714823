import { MutationTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const mutation: MutationTree<StoreFlag.UserStateInterface> = {
  set(state: any, { type, value }) {
    state[type] = value;
  },
  setUser(state: any, value) {
    state.user = value;
  },
  logout(state: any) {
    state.user = null;
  },
};

export default mutation;
