import { StoreFlag } from 'RootDir/declarations/store/store-flag';

function state(): StoreFlag.FiltersStateInterface {
  return {
    usersFilter: {},
    logsFilter: {},
    genresFilter: {},
    authorsFilter: {},
    contactsFilter: {},
    ugramatBooksFilter: {},
    ordersFilter: {},
  }
}

export default state;
