
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    border: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return { }
  },
})
