import { StoreFlag } from 'RootDir/declarations/store/store-flag';
import { MutationTree } from 'vuex';

const mutation: MutationTree<StoreFlag.FiltersStateInterface> = {
  set(state: any, { type, value }) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    state[type] = value;
  },
  destroyAll(state: StoreFlag.FiltersStateInterface) {
    state.usersFilter = {};
    state.logsFilter = {};
    state.genresFilter = {};
    state.authorsFilter = {};
    state.contactsFilter = {};
    state.ugramatBooksFilter = {};
    state.ordersFilter = {};
  },
};

export default mutation;
