import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_icon = _resolveComponent("s-icon")!
  const _component_s_btn = _resolveComponent("s-btn")!
  const _component_page_header_base = _resolveComponent("page-header-base")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_header_base, {
      "view-btn": _ctx.viewBackBtn,
      "route-back-name": _ctx.routeBackName
    }, {
      start: _withCtx(() => [
        (_ctx.isVisibleTooltip)
          ? (_openBlock(), _createBlock(_component_s_icon, {
              key: 0,
              tooltip: _ctx.tooltip,
              iconName: "info",
              size: "large",
              type: _ctx.tooltipType
            }, null, 8, ["tooltip", "type"]))
          : _createCommentVNode("", true)
      ]),
      center: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      end: _withCtx(() => [
        (_ctx.viewCreateBtn)
          ? (_openBlock(), _createBlock(_component_s_btn, {
              key: 0,
              label: _ctx.titleCreateBtn,
              "no-caps": "",
              class: "w-base-small",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCreateBtnClick')))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["view-btn", "route-back-name"])
  ]))
}