// import { MAP_FILE_TYPE_TO_EXTENSIONS } from 'RootDir/common/constants/files';
import { Files } from 'RootDir/components/Files/files.d';
import { isFunction, isObject } from 'lodash';

// type Files = {
//   UPLOAD: {
//     url: string;
//     method: string;
//   },
//   GET: any,
//   DELETE: any,
// }

export const AttachmentApiValidator = (prop: Files): boolean => {
  if (!isObject(prop)) {
    throw new Error('Invalid attachments api.');
  }

  if (
    typeof prop.UPLOAD === 'undefined' ||
    !isObject(prop.UPLOAD) ||
    typeof prop.UPLOAD.url !== 'string' ||
    typeof prop.UPLOAD.method !== 'string'
  ) {
    throw new Error("Invalid attachment's UPLOAD method.");
  }
  if (typeof prop.GET === 'undefined' || !isFunction(prop.GET)) {
    throw new Error("Invalid attachment's GET method.");
  }
  if (typeof prop.DELETE === 'undefined' || !isFunction(prop.DELETE)) {
    throw new Error("Invalid attachment's DELETE method.");
  }

  return true;
};

export const ComponentVariantsValidator = (value: string, variants: Array<string>, type: string): boolean => {
  if (typeof value !== 'string') {
    throw new Error(`${type} type expected to be "string", "${typeof value}" given.`);
  }
  if (!variants.includes(value)) {
    const expected = variants.map((t) => `"${t}"`).join(', ');
    throw new Error(`${type} type should be one of [${expected}], "${value}" given.`);
  }

  return true;
}

// export const validateAcceptFiles = (acceptExtensions: Array<Files.FileTypeExtensions> | Files.FileTypeExtensions): string => {
//   if (typeof acceptExtensions === 'object' && Array.isArray(acceptExtensions)) {
//     let extensions: Array<string> = [];
//     acceptExtensions.forEach((acceptExtensionType) => {
//       const acceptFileExtensions = MAP_FILE_TYPE_TO_EXTENSIONS[acceptExtensionType];
//       if (acceptFileExtensions) {
//         extensions = extensions.concat(
//           acceptFileExtensions.map((extension) => (extension === '*' ? '*' : `.${extension}`))
//         );
//       }
//     });
//     return extensions.join(',');
//   }
//   return acceptExtensions;
// }
