import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "s-modal--form__header-title" }
const _hoisted_2 = { class: "s-modal--form__body" }
const _hoisted_3 = { class: "row justify-end full-width s-modal--form__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: _normalizeClass(["s-modal--form", {
        's-modal--extra-small': _ctx.size === 'extra-small',
        's-modal--small': _ctx.size === 'small',
        's-modal--middle': _ctx.size === 'middle',
        's-modal--large': _ctx.size === 'large',
        's-modal--big': _ctx.size === 'big',
      }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, { class: "s-modal--form__header" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }),
              (_ctx.rightIconClose)
                ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    "no-caps": "",
                    icon: "close",
                    flat: "",
                    round: "",
                    dense: ""
                  }, null, 512)), [
                    [_directive_close_popup]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default"),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer")
            ])
          ]),
          _createVNode(_component_q_separator)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}