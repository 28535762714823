import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    round: _ctx.round,
    square: !_ctx.round,
    icon: _ctx.icon,
    outline: _ctx.outline,
    flat: _ctx.flat,
    color: _ctx.variant
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["round", "square", "icon", "outline", "flat", "color"]))
}