export const RUSSIAN_LOCALE = {
  days: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
  daysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
    '_'
  ),
  monthsShort: 'Янв_Февр_Март_Апр_Май_Июнь_Июль_Авг_Сент_Окт_Нояб_Дек'.split('_'),
  firstDayOfWeek: 1,
};

export const RU_MASK = '##.##.####';

export const ISO_MASK = 'YYYY-MM-DD';
