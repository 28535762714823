export const HTTP_CODE_SUCCESS = {
  OK: 200,
  CREATED: 201,
};

export const HTTP_CODE_ERROR = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  LOGIC_ERROR: 422,
  MANY_REQUESTS: 429,
};
