import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createBlock(_component_q_icon, {
    name: _ctx.iconName,
    size: _ctx.iconSize,
    color: _ctx.type
  }, {
    default: _withCtx(() => [
      (_ctx.isVisibleTooltip)
        ? (_openBlock(), _createBlock(_component_q_tooltip, {
            key: 0,
            class: "s-tooltip",
            delay: _ctx.DELAY_TOOLTIP
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
            ]),
            _: 1
          }, 8, ["delay"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "size", "color"]))
}