import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
    class: "s-input",
    placeholder: "ДД.ММ.ГГГГ",
    outlined: "",
    dense: "",
    disable: _ctx.disable,
    classes: _ctx.classes,
    "hide-bottom-space": _ctx.hideBottomSpace,
    onClick: _ctx.changeVisibility
  }, {
    append: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "event",
        class: "cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_popup_proxy, {
            modelValue: _ctx.visible,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
            "transition-show": "scale",
            "transition-hide": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_date, {
                modelValue: _ctx.date,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                  _ctx.changeDate
                ],
                minimal: "",
                "now-btn": "",
                "navigation-min-year-month": _ctx.minDate,
                "navigation-max-year-month": _ctx.maxDate,
                locale: _ctx.RUSSIAN_LOCALE
              }, null, 8, ["modelValue", "navigation-min-year-month", "navigation-max-year-month", "locale", "onUpdate:modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "disable", "classes", "hide-bottom-space", "onClick"]))
}