import {
  createStore,
  StoreOptions,
  Store as VuexStore,
  CommitOptions
} from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';
import VuexPersistence from 'vuex-persist';
import auth from "./auth";
import app from "./app";
import user from './user';
import filters from './filters';
import paginations from './paginations';
import { Filters } from 'RootDir/declarations/filters';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: VuexStore<StoreFlag.StateInterface>;
  }
}

export type Mutations = {
  ['auth/setAccessToken'](state: StoreFlag.AuthStateInterface, token: string): void;
  ['auth/setRefreshToken'](state: StoreFlag.AuthStateInterface, token: string): void;
  ['user/setUser'](state: StoreFlag.UserStateInterface, user: Administration.SystemUser): void;
  ['user/logout'](state: StoreFlag.UserStateInterface): void;
  ['filters/destroyAll'](state: StoreFlag.FiltersStateInterface): void;
  ['paginations/destroyAll'](state: StoreFlag.FiltersStateInterface): void;
};

// Getters not used. May be need to delete

type Getters = {
  ['auth/isAuthenticated'](): boolean;
  ['auth/accessToken'](): string;
  ['auth/refreshToken'](): string;
  ['user/user'](): Administration.SystemUser;
  ['app/collapseSidebar'](): boolean;
  ['filters/usersFilter'](): Filters.FilterDataTypes;
  ['filters/logsFilter'](): Filters.FilterDataTypes;
  ['filters/authorsFilter'](): Filters.FilterDataTypes;
  ['filters/genresFilter'](): Filters.FilterDataTypes;
  ['filters/contactsFilter'](): Filters.FilterDataTypes;
  ['filters/ugramatBooksFilter'](): Filters.FilterDataTypes;
  ['filters/ordersFilter'](): Filters.FilterDataTypes;
  ['paginations/usersPagination'](): Filters.FilterDataTypes;
  ['paginations/logsPagination'](): Filters.Pagination;
  ['paginations/genresPagination'](): Filters.Pagination;
  ['paginations/authorsPagination'](): Filters.Pagination;
  ['paginations/contactsPagination'](): Filters.Pagination;
  ['paginations/ugramatBooksPagination'](): Filters.Pagination;
  ['paginations/ordersPagination'](): Filters.Pagination;
};

export type Store = Omit<
  VuexStore<StoreFlag.StateInterface>,
  'getters'
   | 'commit'
   | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: () => ReturnType<Getters[K]>;
    // [K in keyof UserGetters]: ReturnType<UserGetters[K]>;
    }
  };


const store = {
  modules: {
    auth,
    app,
    user,
    filters,
    paginations,
  },
  strict: true,
  plugins: [
    new VuexPersistence({
      modules: ['auth', 'user', 'filters', 'paginations'],
      storage: window.localStorage,
    }).plugin,
  ],
} as StoreOptions<StoreFlag.StateInterface>;

export default createStore(store)
