/**
 * Переданное значение пустая строка или null
 * @param str
 * @returns
 */
export const isEmptyOrNullString = (str: string | null | number): boolean =>
  str === null || (typeof str === 'string' && !str.trim());

/**
 * Переданная строка равна "length"
 *
 * @param {*} str
 * @param {*} length
 * @returns
 */
export const isStringEqualTo = (str: string, length: number) => typeof str === 'string' && str.length == length;

