import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_pagination = _resolveComponent("q-pagination")!

  return (_openBlock(), _createBlock(_component_q_pagination, {
    max: _ctx.max,
    "direction-links": _ctx.directionLinks,
    color: "dark",
    "active-color": "primary"
  }, null, 8, ["max", "direction-links"]))
}