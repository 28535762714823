
import { defineComponent, computed, PropType } from 'vue';
import { isEmptyOrNullString } from 'RootDir/validators/strings';
import PageHeaderBase from '../Page/PageHeaderBase.vue';
import { Header } from './s-header';

export default defineComponent({
  name: 'SHeaderDefault',
  components: {
    PageHeaderBase,
  },
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    tooltipType: {
      type: String as PropType<Header.TooltipTypes>,
      default: 'primary',
    },
    title: {
      type: String,
      required: true,
    },
    titleSize: {
      type: String as PropType<Header.TitleSize>,
      default: 'base'
    },
    viewCreateBtn: {
      type: Boolean,
      default: false,
    },
    viewBackBtn:{
      type: Boolean,
      default: false,
    },
    titleCreateBtn: {
      type: String,
      default: 'Создать',
    },
    routeBackName: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const isVisibleTooltip = computed((): boolean => !isEmptyOrNullString(props.tooltip));

    const titleSizeClass = computed((): string => {
      switch (props.titleSize) {
        case 'small':
          return 'fs-base-xxl';
        case 'base':
          return 'fs-base-xxxl';
        case 'medium':
          return 'fs-small';
        default:
          return '25px';
      }
    });
    return {
      isVisibleTooltip,
      titleSizeClass,
    }
  },
})
