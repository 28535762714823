declare const API_SERVER: string;

declare const DADATA_API_KEY: string;

declare const BANNER_TEST_MODE: string;

declare const LIBRARY_TG_URL: string;

declare const LANDING_URL: string;

export const SERVER_API: string = API_SERVER;

export const BASE_API_SERVER = `${SERVER_API}/api`;

export const DADATA_KEY: string = DADATA_API_KEY;

export const STORAGE_SERVER_API = `${SERVER_API}/storage`;

export const TEST_MODE_BANNER: boolean = BANNER_TEST_MODE === 'true';

export const TG_LIBRARY_URL = LIBRARY_TG_URL;

export const UGRAMAT_LANDING_URL = LANDING_URL;

export const TOMMOROW_ORDERS_URL = `${STORAGE_SERVER_API}/orders.xlsx`;
