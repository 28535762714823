
import { defineComponent, PropType, ref } from 'vue';
import { BtnIcons } from 'RootDir/declarations/components/s-btn-icon';
import { ComponentVariantsValidator } from 'RootDir/validators/components';

const btnVariants = [
  'primary',
  'secondary',
  'accent',
  'dark',
];

export default defineComponent({
  name: 'SBtnIcon',
  props: {
    variant: {
      type: String as PropType<BtnIcons.BtnIconVariants>,
      default: 'primary',
      validator: (el:string) => ComponentVariantsValidator(el, btnVariants, 'Button icon'),
    },
    icon: {
      type: String,
      required: true,
    },
    round: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {

    }
  },
})
