
import axios from "axios";
import { AUTHORIZATION } from "RootDir/common/endpoints/auth";
import { Auth } from "RootDir/declarations/endpoints/auth.d";

interface LoginData {
  username: string;
  password: string;
}

interface RefreshResponse {
  access_token: string;
  refresh_token: string;
}

export default class AccessApi {
  public static async login(data: LoginData): Promise<Auth.LoginResponseInfo> {
    const responseData: Auth.LoginResponseInfo = await axios({
      ...AUTHORIZATION.LOGIN,
      data,
    })
      .then((r): Auth.LoginResponseInfo => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }

  public static async logout(): Promise<{ message: string }> {
    const responseData: { message: string } = await axios({
      ...AUTHORIZATION.LOGOUT,
    })
      .then((r): { message: string } => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }

  public static async refresh(data: {refresh_token: string}): Promise<{ data: RefreshResponse }> {
    const responseData: { data: RefreshResponse } = await axios({
      ...AUTHORIZATION.REFRESH_TOKEN,
      data,
    })
      .then((r): { data: RefreshResponse } => r.data)
      .catch((e) => {
        throw e;
      });

    return responseData;
  }
}
