import { MutationTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const mutation: MutationTree<StoreFlag.AuthStateInterface> = {
  set(state: any, { type, value }) {
    state[type] = value;
  },
  setAccessToken(state: StoreFlag.AuthStateInterface, token: string) {
    state.accessToken = token;
    localStorage.setItem('accessToken', token);
  },
  setRefreshToken(state: StoreFlag.AuthStateInterface, token: string) {
    state.refreshToken = token;
    localStorage.setItem('refreshToken', token);
  },
};

export default mutation;
