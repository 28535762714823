import { ActionTree } from 'vuex';
import { Commit } from 'vuex/types';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';
import { Filters } from 'RootDir/declarations/filters';

export enum ActionsTypes {
  SET_ACTION_PAGINATIONS = 'setPaginations',
}

export type PaginationActionTypes = {
  [ActionsTypes.SET_ACTION_PAGINATIONS](
    { commit }: { commit: Commit },
    { data, paginationType }: {data: Filters.Pagination; paginationType: string},
  ): void;
}

const actions: ActionTree<StoreFlag.PaginationsStateInterface, StoreFlag.StateInterface> = {
  setPaginations({ commit }, {
    data,
    paginationType,
  }: {data: Filters.Pagination; paginationType: string}) {
    commit('set', { type: paginationType, value: data});
  }
};

export default actions;
