import { GetterTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const getters: GetterTree<StoreFlag.FiltersStateInterface, StoreFlag.StateInterface> = {
  usersFilter: (state) => state.usersFilter,
  logsFilter: (state) => state.usersFilter,
  genresFilter: (state) => state.genresFilter,
  authorsFilter: (state) => state.authorsFilter,
  contactsFilter: (state) => state.contactsFilter,
  ugramatBooksFilter: (state) => state.ugramatBooksFilter,
  ordersFilter: (state) => state.ordersFilter,
};

export default getters;
