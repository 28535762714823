import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header--back" }
const _hoisted_2 = { class: "page-header--start" }
const _hoisted_3 = { class: "page-header--center" }
const _hoisted_4 = {
  class: "page-header--end",
  align: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_btn = _resolveComponent("s-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.viewBtn)
        ? (_openBlock(), _createBlock(_component_s_btn, {
            key: 0,
            label: "НАЗАД",
            "no-caps": "",
            class: "w-base-small",
            variant: "accent",
            onClick: _ctx.back
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["page-header--container", {'mt-base-xl' : _ctx.viewBtn}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "start")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "center")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "end")
      ])
    ], 2)
  ], 64))
}