import { RouteRecordRaw } from 'vue-router';

const BooksPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/UgramatBooks/pages/List.vue'
  );

const BookPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/UgramatBooks/pages/Index.vue'
  );

export default [
  {
    path: "",
    component: BooksPage,
    name: "ugramat-books",
    meta: {
      title: 'Ugramat.Books Книги'
    }
  },
  {
    path: ":id",
    component: BookPage,
    name: "ugramat-book",
    meta: {
      title: 'Ugramat.Books Книга'
    }
  },
] as Array<RouteRecordRaw>;
