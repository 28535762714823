import { ActionTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const actions: ActionTree<StoreFlag.AppStateInterface, StoreFlag.StateInterface> = {
  setCollapseSidebar({ commit }, val: boolean) {
    commit('set', { type: 'collapseSidebar', value: val });
  },
};

export default actions;
