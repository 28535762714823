
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ErrorMessage',
  props: {
    message: {
      type: String,
      required: true,
      default: "info",
    },
  },
})
