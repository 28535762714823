import { computed, Ref, ref } from "vue";
import { generateFIO } from "RootDir/utils/user";
import { ROLE_COURIER, ROLE_LIBRARIAN, ROLE_SUPERADMINISTRATOR, ROLE_USER } from "RootDir/common/constants/roles";
import { useStore } from "vuex";
import AccountApi from "RootDir/modules/Account/account.api";
import AccessApi from "@/modules/Authorization/pages/access.api";

export function useCurrentUser() {
  const store = useStore();

  const currentUser = computed(() => store.getters['user/user']);

  const accessToken = computed(() => store.getters['auth/accessToken']);

  const refreshToken = computed(() => store.getters['auth/refreshToken']);

  const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

  const isSimpleUser = computed(() => currentUser.value.role_id === ROLE_USER.id);

  const isRoot = computed(() => currentUser.value.role_id === ROLE_SUPERADMINISTRATOR.id);

  const isLibrarian = computed(() => currentUser.value.role_id === ROLE_LIBRARIAN.id);

  const isCourier = computed(() => currentUser.value.role_id === ROLE_COURIER.id);

  const $userDataSet = {
    setToken(accessToken: string | null, refreshToken: string | null) {
      store.commit('auth/setAccessToken', accessToken);
      store.commit('auth/setRefreshToken', refreshToken);
    },
    setUser(user: Administration.SystemUser) {
      store.commit('user/setUser', user);
    },
    logout() {
      this.setToken(null, null);
      store.commit('user/logout');
    },
  };

  const role = computed(() => currentUser.value.role);

  const roleName = computed(() => currentUser.value.role.name);

  const fioFull = computed(() => generateFIO(currentUser.value.first_name, currentUser.value.second_name, currentUser.value.last_name));

  const getUserInfo = async () => {
    await AccountApi.index().then(
      (r) => {
        $userDataSet.setUser(r.data);
      }
    );
  }

  const sendRefreshToken = async (): Promise<boolean> => {
    const data = {
      refresh_token: refreshToken.value,
    };
    await AccessApi.refresh(data).then(
      (r) => {
        $userDataSet.setToken(r.data.access_token, r.data.refresh_token);
        return true;
      },
      (e) => $userDataSet.logout()
    );
    return false;
  }

  return {
    currentUser,
    accessToken,
    sendRefreshToken,
    isAuthenticated,
    $userDataSet,
    role,
    fioFull,
    isSimpleUser,
    isRoot,
    isLibrarian,
    isCourier,
    roleName,
    getUserInfo,
    refreshToken,
  }
}
