import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_btn_icon = _resolveComponent("s-btn-icon")!
  const _component_q_banner = _resolveComponent("q-banner")!

  return (_openBlock(), _createBlock(_component_q_banner, {
    dense: "",
    "inline-actions": "",
    class: "br-xxl text-white bg-color-red"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_s_btn_icon, {
        variant: "secondary",
        flat: "",
        dense: "",
        icon: "info"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }))
}