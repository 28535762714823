
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SFilters',
  props: {

  },
  setup() {
    const show = ref(false);
    return {
      show,
    }
  },
})
