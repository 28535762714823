import { ActionContext, ActionTree } from 'vuex';
import axios, { AxiosResponse } from 'axios';
import { AUTHORIZATION } from 'RootDir/common/endpoints/auth';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';
// import { parseJwt } from '@/common/helpers';

const actions: ActionTree<StoreFlag.AuthStateInterface, StoreFlag.StateInterface> = {
  setAccessToken({ commit }, token) {
    commit('set', { type: 'accessToken', value: token });
  },
  setRefreshToken({ commit }, token) {
    commit('set', { type: 'refreshToken', value: token });
  },
  logout({ commit }) {
    commit('set', { type: 'accessToken', value: null });
    commit('set', { type: 'refreshToken', value: null });
    localStorage.clear();
  },

  async refreshToken({
    state,
    dispatch,
  }: ActionContext<StoreFlag.AuthStateInterface, StoreFlag.StateInterface>): Promise<'REFRESHED'> {
    /**
     * Реализовать
     */
    // const data = (({ refreshToken: token }) => ({ token }))(state);
    // const {
    //   data: { token: newToken, user: userInfo, userProfiles },
    // } = (await axios({ ...AUTHORIZATION.REFRESH, data })) as AxiosResponse<{
    //   token: string;
    //   user: Record<string, unknown>;
    //   userProfiles: Array<unknown>;
    // }>;
    // const newRefreshToken = parseJwt(newToken)?.refresh_token ?? null;
    // await dispatch('setToken', newToken);
    // await dispatch('setRefreshToken', newRefreshToken);
    // await dispatch('user/setUser', { token: newToken, ...userInfo }, { root: true });
    // await dispatch('user/setUserProfiles', userProfiles, { root: true });

    return 'REFRESHED';
  }
}

export default actions;
