import { Module } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';
import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const authModule: Module<StoreFlag.AuthStateInterface, StoreFlag.StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default authModule;
