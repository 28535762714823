
import { computed, defineComponent, PropType } from 'vue';
import { isEmptyOrNullString } from 'RootDir/validators/strings';
import { DELAY_TOOLTIP } from './icon-config';
import { Icons } from 'RootDir/declarations/components/s-icon';

export default defineComponent({
  name: 'SIcon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<Icons.IconTypes>,
      default: 'dark',
    },
    size: {
      type: String as PropType<Icons.IconSizes>,
      default: 'medium',
    },
  },
  setup(props) {
    const iconSize = computed((): string => {
      switch (props.size) {
        case 'small':
          return '18px';
        case 'medium':
          return '20px';
        case 'large':
          return '22px';
        case 'xl':
          return '35px';
        default:
          return '22px';
      }
    });

    const isVisibleTooltip = computed((): boolean => !isEmptyOrNullString(props.tooltip));
    return {
      iconSize,
      isVisibleTooltip,
      DELAY_TOOLTIP
    }
  },
})
