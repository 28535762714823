import { GetterTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const getters: GetterTree<StoreFlag.AuthStateInterface, StoreFlag.StateInterface> = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  isAuthenticated: (state) => !!state.accessToken,
};

export default getters;
