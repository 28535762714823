import { StoreFlag } from 'RootDir/declarations/store/store-flag';

function state(): StoreFlag.PaginationsStateInterface {
  return {
    logsPagination: {},
    usersPagination: {},
    contactsPagination: {},
    authorsPagination: {},
    genresPagination: {},
    ugramatBooksPagination: {},
    ordersPagination: {},
  }
}

export default state;
