import { RouteRecordRaw } from 'vue-router';

const BoxesPage = (): Promise<typeof import('*.vue')> =>
  import(
    'RootDir/modules/Boxes/pages/List.vue'
  );

export default [
  {
    path: "",
    component: BoxesPage,
    name: "boxes",
    meta: {
      title: 'Ugramat.Books Постаматы'
    }
  },
] as Array<RouteRecordRaw>;
