/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEndpointData } from "RootDir/declarations/endpoint";
import { BASE_API_SERVER } from "../constants/common";

const usersUrl: string = `${BASE_API_SERVER}/administration/users`;

export const USERS = {
  LIST: {
    method: 'GET',
    url: `${usersUrl}/`,
  } as IEndpointData,
  INDEX(id: number): IEndpointData {
    return {
      method: 'GET',
      url: `${usersUrl}/${id}`,
    }
  },
  UPDATE_ROLE(userId: number, roleId: number): IEndpointData {
    return {
      method: 'PUT',
      url: `${usersUrl}/${userId}/change-role/${roleId}`,
    }
  },
  DESTROY(id: number): IEndpointData {
    return {
      method: 'DELETE',
      url: `${usersUrl}/${id}`,
    }
  },
  RESTORE(id: number): IEndpointData {
    return {
      method: 'PUT',
      url: `${usersUrl}/restore/${id}`,
    }
  },
};

const accountUrl: string = `${BASE_API_SERVER}/account`;

export const ACCOUNT = {
  INDEX: {
    method: 'GET',
    url: `${accountUrl}`,
  } as IEndpointData,
  UPDATE: {
    method: 'PUT',
    url: `${accountUrl}`,
  } as IEndpointData,
  UPDATE_ADDRESS: {
    method: 'PUT',
    url: `${accountUrl}/address`,
  } as IEndpointData,
};

