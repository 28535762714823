
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SInput',
  props: {
    disable: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideBottomSpace: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props, { slots }) {
    const classesArray = ref([
      's-input',
      props.disable ? 's-input--disable' : '',
      props.required ? 'required' : '',
    ] as Array<string>);

    const classes = ref(classesArray.value.join(' ') as string);
    return {
      classes,
      slots,
    }
  },
})
