import { ActionTree } from 'vuex';
import { StoreFlag } from "RootDir/declarations/store/store-flag";


const actions: ActionTree<StoreFlag.UserStateInterface, StoreFlag.StateInterface> = {
  setUser({ commit }, userData) {
    commit('set', { type: 'user', value: userData });
  },
  updateUser({ commit }, userData) {
    Object.keys(userData).forEach((key) => {
      if (userData[key] !== null || userData[key] !== 'undefined') {
        commit('setUser', { key, value: userData[key] });
      }
    });
  },
}

export default actions;
