import { GetterTree } from 'vuex';
import { StoreFlag } from 'RootDir/declarations/store/store-flag';

const getters: GetterTree<StoreFlag.PaginationsStateInterface, StoreFlag.StateInterface> = {
  usersPagination: (state) => state.usersPagination,
  logsPagination: (state) => state.logsPagination,
  genresPagination: (state) => state.genresPagination,
  authorsPagination: (state) => state.authorsPagination,
  contactsPagination: (state) => state.contactsPagination,
  ugramatBooksPagination: (state) => state.ugramatBooksPagination,
  ordersPagination: (state) => state.ordersPagination,
};

export default getters;
